import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Form, Col, Row, Button, Modal } from 'react-bootstrap'
import Footer from '../components/footer'
import _ from 'lodash'
import Container from 'react-bootstrap/Container'
import CustomerInfos from './../components/CustomerInfos'
import { bdstatistics, apiURL, mqStatistics } from '../components/census'
import './../components/media.css'
import './../components/common.css'

import { data } from '../assets/trade.json'

const LoginPage = () => {
  const [ validated, setValidated ] = React.useState(false)
  const [ none, setNone ] = React.useState(false)
  const [ validations, setVaildations ] = React.useState()
  const [ changeSite, setChangeSite ] = React.useState(false)
  const [ show, setShow ] = React.useState(false)
  const [ inputData, setInputData ] = React.useState({
    company_name: null,
    industry: null,
    contact_name: null,
    phone_number: null,
    email: null,
    user_flag: '申请试用'
  })

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    if(typeof window !== "undefined") {
      window.addEventListener('resize', (event) => {
        let width = document.body.clientWidth
        changeWidth(width)
      })
      changeWidth(document.body.clientWidth)
    }
  }, [])

  const changeWidth = width => {
    if(width < 768) {
      setChangeSite(true)
    }else {
      setChangeSite(false)
    }
  }

  return (
  <>  
    <div className="home-bg contact-box" style={{ background: 'none', height: '7.5rem' }}>
      <Layout>
        <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
      </Layout>
    </div>
    <Container>
      <CustomerInfos />
    </Container>
    <Footer></Footer> 
  </>  
  )  
}

export default LoginPage